.keypad {
  background-color: $border-color;
  flex: 3;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex: 1;

  button,
  .number {
    background-color: $default-key-color;
    border: solid 1px $border-color;
    outline: none;
    color: $text-color;
    font-size: 24px;
    line-height: 30px;
    flex: 1;
    padding: 0;
    cursor: pointer;
    transition: 0.4s;
  
    &:hover,
    &:active {
      background-color: darken($color: $default-key-color, $amount: 3%);
    }
  }

  .function {
    background-color: $function-key-color;
  
    &:hover,
    &:active {
      background-color: darken($color: $function-key-color, $amount: 3%);
    }
  }
  
  .operation {
    font-size: 30px;
    background-color: $operation-key-color;
    
    &:hover,
    &:active,
    &:focus {
      background-color: darken($color: $operation-key-color, $amount: 3%);
    }
  }
  
  .double {
    text-align: left;
    padding: 0 13% 0 12%;
  }
}