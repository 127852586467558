body {
  background-color: $background-color;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.calculator {
  display: flex;
  flex-direction: column;
  height: 100vh;
}